import { PermissionCode, ResourceCode } from '@/const/code'

export default [
  {
    path: '/exam_questions/qb_question_types',
    name: 'qbQuestionTypes',
    component: () => import('@/views/exam-question/qb-question-type/Index.vue'),
    meta: {
      pageTitle: 'Danh sách loại câu hỏi',
      breadcrumb: [
        {
          text: 'Quản lý đề thi',
          active: false,
        },
        {
          text: 'Loại câu hỏi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.QB_QUESTION_TYPE,
    },
  },
  {
    path: '/exam_questions/qb_criteria_mix_exams',
    name: 'qbCriteriaMixExams',
    component: () => import('@/views/exam-question/qb-criteria-mix-exam/Index.vue'),
    meta: {
      pageTitle: 'Danh sách tiêu chí trộn đề thi',
      breadcrumb: [
        {
          text: 'Quản lý đề thi',
          active: false,
        },
        {
          text: 'Tiêu chí trộn đề thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.QB_CRITERIA_MIX_EXAM,
    },
  },
  {
    path: '/exam_questions/qb_question_scales',
    name: 'qbQuestionScales',
    component: () => import('@/views/exam-question/qb-question-scale/Index.vue'),
    meta: {
      pageTitle: 'Danh sách thang điểm câu hỏi',
      breadcrumb: [
        {
          text: 'Quản lý đề thi',
          active: false,
        },
        {
          text: 'Thang điểm câu hỏi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.QB_QUESTION_SCALE,
    },
  },
  {
    path: '/exam_questions/qb_questions',
    name: 'qbQuestions',
    component: () => import('@/views/exam-question/qb-question/Index.vue'),
    meta: {
      pageTitle: 'Danh sách câu hỏi',
      breadcrumb: [
        {
          text: 'Quản lý đề thi',
          active: false,
        },
        {
          text: 'Danh sách câu hỏi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.QB_QUESTION,
    },
  },
  {
    path: '/exam_questions/qb_exam_structures',
    name: 'qbExamStructures',
    component: () => import('@/views/exam-question/qb-exam-structure/Index.vue'),
    meta: {
      pageTitle: 'Cấu trúc đề thi',
      breadcrumb: [
        {
          text: 'Quản lý đề thi',
          active: false,
        },
        {
          text: 'Cấu trúc đề thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.QB_EXAM_STRUCTURE,
    },
  },
  {
    path: '/exam_questions/qb_exams',
    name: 'qbExams',
    component: () => import('@/views/exam-question/qb-exam/Index.vue'),
    meta: {
      pageTitle: 'Đề thi',
      breadcrumb: [
        {
          text: 'Quản lý đề thi',
          active: false,
        },
        {
          text: 'Đề thi',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.QB_EXAM,
    },
  },
  {
    path: '/exam_questions/qb_exam_course_semesters',
    name: 'qbExamCourseSemesters',
    component: () => import('@/views/exam-question/qb-exam-course-semester/QbExamCourseSemester.vue'),
    meta: {
      pageTitle: 'Quản lý đề thi trong học kỳ',
      breadcrumb: [
        {
          text: 'Quản lý đề thi',
          active: false,
        },
        {
          text: 'Quản lý đề thi trong học kỳ',
          active: true,
        },
      ],
      action: PermissionCode.READ,
      resource: ResourceCode.QB_EXAM_COURSE_SEMESTER,
    },
  },
]
