import Vue from 'vue'
import Vuex from 'vuex'

// Modules
// eslint-disable-next-line import/no-cycle
import auth from '@/store/auth'
import menu from '@/store/authorization/menu'
import permission from '@/store/authorization/permission'
import award from '@/store/category/award'
import revenuetype from '@/store/category/revenuetype'
import allowance from '@/store/category/allowance'
import discipline from '@/store/category/discipline'
import policies from '@/store/category/policies'
import role from '@/store/authorization/role'
import programme from '@/store/programme'
import semesterType from '@/store/category/semesterType'
import semester from '@/store/category/semester'
import category from '@/store/category'
import building from '@/store/category/building'
import classes from '@/store/category/classes'
import rooms from '@/store/category/rooms'
import trainingForm from '@/store/category/fact-training-form'
import trainingSystem from '@/store/category/trainingSystem'
import course from '@/store/category/course'
import account from '@/store/authorization/account'
import resource from '@/store/authorization/resource'
import studentAllowance from '@/store/studentallowance'
import studentPolicies from '@/store/student-policies'
import studentAward from '@/store/studentaward'
import studentCgpa from '@/store/student-cgpa'
import studentDiscipline from '@/store/studentdiscipline'
import studentScholarship from '@/store/studentscholarship'
import programmeSubject from '@/store/programme-subject'
import dropdown from '@/store/common/dropdown'
import creditClass from '@/store/credit-class'
import department from '@/store/category/department'
import major from '@/store/category/major'
import subjectType from '@/store/category/subjectType'
import subject from '@/store/category/subject'
import examinationRoom from '@/store/examinationroom'
import studentExaminationRoom from '@/store/studentexaminationroom'
import employee from '@/store/category/employee'
import teacherTeam from '@/store/category/teacherTeam'
import creditClassTeacher from '@/store/credit-class-teacher'
import creditClassStudent from '@/store/credit-class-student'
import importStudent from '@/store/import-student'
import teacher from '@/store/teacherManagement/teachers'
import teacherSubject from '@/store/teacherManagement/teacher-subject'
import schedule from '@/store/schedule'
import day from '@/store/day'
import turn from '@/store/turn'
import revenue from '@/store/finance/revenue'
import revenuePlan from '@/store/finance/revenue_plan'
import revenueSchedule from '@/store/finance/revenue_schedule'
import exemption from '@/store/finance/exemption'
import receipt from '@/store/finance/receipt'
import courseSemester from '@/store/course-semester'
import studentClass from '@/store/student-class'
import reportTeachingExamCondition from '@/store/report/teaching-exam-condition'
import reportPointByCreditClass from '@/store/report/point-by-class-report'
import creditClassRoom from '@/store/credit-class-room'
import avgStudentClass from '@/store/report/avg-student-class'
import student from '@/store/category/student'
import classReceiptsCourseSemester from '@/store/report/class-receipts-course-semester'
import creditClassStudentPoint from '@/store/report/credit-class-student-point'
import reportPaymentStatusByCourseSemester from '@/store/report/payment-status-by-course-semester'
import conduct from '@/store/category/conduct'
import graduationCondition from '@/store/graduation/graduation_condition'
import conductCriteria from '@/store/category/conduct-criteria'
import graduationClass from '@/store/graduation/graduation-class'
import programmeSubjectGroup from '@/store/programme-subject-group'
import portalArticle from '@/store/category/portal_article'
import diploma from '@/store/diploma'
import diplomaStudent from '@/store/diploma-student'
import studentReserve from '@/store/student-reserve'
import curriculumVitae from '@/store/curriculum-vitae'
import reportTeacherSpecializationAssignment from '@/store/report/teacher-specialization-assignment'
import classLearningAlert from '@/store/report/class-learning-alert'
import diplomaPlanStudentClass from '@/store/diploma-plan-student-class'
import parameter from '@/store/parameter'
import examinationScheduler from '@/store/examination-scheduler'
import calculateCgpa from '@/store/calculate-cgpa'
import examinationSchedulerSubject from '@/store/examination-scheduler-subject'
import creditClassBySubject from '@/store/report/credit-class-by-subject'
import teacherDocument from '@/store/teacher-document'
import creditClassMedia from '@/store/credit-class-media'
import accountFcmToken from '@/store/account-fcm-token'
import notification from '@/store/notification'
import studentTransfer from '@/store/student-transfer'
import retakeExamSecondTime from '@/store/report/retake-exam-second-time'
import studentBeRepeated from '@/store/report/student-be-repeated'
import examinationRoomTeacher from '@/store/examination-room-teacher'
import semesterExam from '@/store/report/semester-exam'
import programmeReplaceThesisSubject from '@/store/programme-replace-thesis-subject'
import studentSuspend from '@/store/student-suspend'
import documents from '@/store/documents'
import reportCertification from '@/store/report/certification'
import logActivities from '@/store/log_activities'
import carousel from '@/store/fact-carousel'
import maintenance from '@/store/maintenance'
import maintenanceBackup from '@/store/maintenance-backup'
import maintenanceRecovery from '@/store/maintenance-recovery'
import studentsRegisteredBySubject from '@/store/report/students_registered_by_subject'
import research from '@/store/research'
import reviewBoard from '@/store/research/review-board'
import factScientificTopics from '@/store/research/fact_scientific_topics'
import factTopicLevels from '@/store/research/fact_topic_levels'
import factResearchRoles from '@/store/research/fact_research_roles'
import factResearchFields from '@/store/research/fact_research_fields'
import reviewer from '@/store/research/reviewer'
import member from '@/store/research/member'
import approvalResearch from '@/store/research/approval-research'
import researchArticle from '@/store/research/article'
import researchArticleRole from '@/store/research/research_article_role'
import manageReport from '@/store/report/manage'
import scoreLockPassword from '@/store/score-lock-password'
import deductLevel from '@/store/examination/deduct-level'
import scoringFormula from '@/store/scoring-formula'
import generalScore from '@/store/general-score'
import componentScore from '@/store/component-score'
import invention from '@/store/research/invention'
import factResearchInventionType from '@/store/category/fact-research-invention-type'
import inventionMember from '@/store/research/invention-member'
import techTransfer from '@/store/research/tech-transfer'
import techTransferMember from '@/store/research/tech-transfer-member'
import researchStudent from '@/store/research/student'
import researchStudentMember from '@/store/research/student-member/index'
import researchInstructor from '@/store/research/instructor/index'
import factResearchArticleRole from '@/store/category/fact-research-article-role'
import researchArticleMember from '@/store/research/article-member/index'
import factProvince from '@/store/category/fact-province'
import factNationality from '@/store/category/fact-nationality'
import factAward from '@/store/category/fact-award'
import factReligion from '@/store/category/fact-religion'
import factStudentStatus from '@/store/category/fact-student-status'
import factStudentCreditClassStatus from '@/store/category/fact-student-credit-class-status'
import factWard from '@/store/category/fact-ward'
import factArticleCategory from '@/store/category/fact-article-category'
import factInfringe from '@/store/category/fact-infringe'
import factScholarship from '@/store/category/fact-scholarship'
import factTitle from '@/store/category/fact-title'
import factYear from '@/store/category/fact-year'
import factOrganization from '@/store/category/fact-organization'
import systemParameter from '@/store/category/systemParameter'
import qrEmployeeStudent from '@/store/qr-report/qr-employee-student'
import factDiscipline from '@/store/category/fact-discipline'
import factEthnic from '@/store/category/fact-ethnic'
import chart from '@/store/category/chart'
import departmentType from '@/store/category/department-type'
import countStudentRegisterSubject from '@/store/report/count-student-register-subject'
import studentCancelSubject from '@/store/report/student-cancel-subject'
import factAcademicRank from '@/store/category/fact-academic-rank'
import factAcademicDegree from '@/store/category/fact-academic-degree'
import majorApproval from '@/store/major-approval'
import specialize from '@/store/major-approval/specialize'
import studentChoice from '@/store/major-approval/student-choice'
import majorApprovalStudent from '@/store/major-approval/major-approval-student'
import configStudentCode from '@/store/config-student-code'
import elearning from '@/store/elearning'
import digitalLibrary from '@/store/digital-library'
import factAdmissionSubject from '@/store/category/fact-admission-subject'
import factAdmissionBlock from '@/store/category/fact-admission-block'
import factAdmissionRole from '@/store/category/fact-admission-role'
import factAdmissionSubjectType from '@/store/category/fact-admission-subject-type'
import factAdmissionFormType from '@/store/category/fact-admission-form-type'
import admissionRound from '@/store/admission/admission-round'
import admissionCommittee from '@/store/admission/admission-committee'
import admissionCommitteeEmployee from '@/store/admission/admission-committee-employee'
import admissionForm from '@/store/admission/admission-form'
import admissionThresholdScore from '@/store/admission/admission-threshold-score'
import factAdmissionConduct from '@/store/category/fact-admission-conduct'
import factAdmissionCriteriaType from '@/store/category/fact-admission-criteria-type'
import factAdmissionStudentType from '@/store/category/fact-admission-student-type'
import factAdmissionViolateType from '@/store/category/fact-admission-violate-type'
import factAdmissionDiscipline from '@/store/category/fact-admission-discipline'
import factAdmissionCertificateType from '@/store/category/fact-admission-certificate-type'
import factPriorityZone from '@/store/category/fact-priority-zone'
import factHighSchool from '@/store/category/fact-high-school'
import factAcademicPerformance from '@/store/category/fact-academic-performance'
import factAdmissionConfirmationType from '@/store/category/fact-admission-confirmation-type'
import factAdmissionLanguageCertificateType from '@/store/category/fact-admission-language-certificate-type'
import admissionMajor from '@/store/admission/admission-major'
import admissionMajorForm from '@/store/admission/admission-major-form'
import admissionPriorityScore from '@/store/admission/admission-priority-score'
import admissionRevenueType from '@/store/admission/admission-revenue-type'
import admissionRevenue from '@/store/admission/admission-revenue'
import criteriaSet from '@/store/admission/criteria-set'
import admissionRevenuePlan from '@/store/admission/admission-revenue-plan'
import admissionRevenueSchedule from '@/store/admission/admission-revenue-schedule'
import criteria from '@/store/admission/criteria'
import admissionLanguageScore from '@/store/admission/admission-language-score'
import admissionCarousel from '@/store/admission/admission-carousel'
import admissionConfirmCertificate from '@/store/admission/admission-confirm-certificate'
import formulaPriorityScore from '@/store/admission/formula-priority-score'
import admissionConfirmationCertificateAccept from '@/store/admission/admission-confirmation-certificate-accept'
import formulaAdmissionScore from '@/store/admission/formula-admission-score'
import formulaAdmissionScoreCoefficient from '@/store/admission/formula-admission-score-coefficient'
import applicantStudentPriorityOrder from '@/store/admission/applicant-student-priority-order'
import admissionExaminationScheduler from '@/store/admission/admission-examination-scheduler'
import admissionExaminationSchedulerSubject from '@/store/admission/admission-examination-scheduler-subject'
import admissionExaminationRoom from '@/store/admission/admission-examination-room'
import applicantStudentExaminationRoom from '@/store/admission/applicant-student-examination-room'
import admissionExaminationRoomTeacher from '@/store/admission/admission-examination-room-teacher'
import factAdmissionScoreType from '@/store/category/fact-admission-score-type'
import admissionScore from '@/store/admission/admission-score'
import aspirationInformation from '@/store/admission/aspiration-information'
import admissionStandardScore from '@/store/admission/admission-standard-score'
import evaluateObjectGroup from '@/store/evaluate-object-group'
import evaluatedObjectGroup from '@/store/evaluated-object-group'
import setOfCriteria from '@/store/set-of-criteria'
import setOfCriteriaObject from '@/store/set-of-criteria-object'
import evaluateManagement from '@/store/evaluate-management'
import studentEvaluateCreditClass from '@/store/student-evaluate-credit-class'
import setOfEvaluateLevel from '@/store/set-of-evaluate-level'
import setOfCriteriaType from '@/store/set-of-criteria-type'
import employeeEvaluate from '@/store/employee-evaluate'
import employeeSetOfCriteriaObject from '@/store/employee-set-of-criteria-object'
import studentEvaluate from '@/store/student-evaluate'
import payment from '@/store/payment'
import backupSchedule from '@/store/backup-schedule'
import countStudentCancelSubject from '@/store/report/count-student-cancel-subject'
import factDisability from '@/store/category/fact-disability'
import factGender from '@/store/category/fact-gender'
import confirmAdmission from '@/store/admission/confirm-admission'
import credentialType from '@/store/credential-type'
import credential from '@/store/credential'
import credentialImplement from '@/store/credential-implement'
import certificateBlank from '@/store/certificate_blank'
import credentialSample from '@/store/credential-sample'
import credentialCondition from '@/store/credential-condition'
import certificateProcessType from '@/store/certificate-process-type'
import credentialProcessType from '@/store/credential-process-type'
import credentialSampleConfig from '@/store/credential-sample-config'
import credentialCreate from '@/store/credential-create'
import credentialStorage from '@/store/credential-storage'
import credentialProcessRequest from '@/store/credential-process-request'
import processRequest from '@/store/process-request'
import certificateImplement from '@/store/certificate-implement'
import practicalResearchProgram from '@/store/practical-research-program'
import graduationDissertation from '@/store/category/graduation-dissertation'
import importAdmission from '@/store/admission/import-admission'
import politicAdmissionConfirm from '@/store/admission/politic-admission-confirm'
import practicalResearchClassGroup from '@/store/practical-research-class-group'
import practicalResearchClassStudent from '@/store/practical-research-class-student'
import subjectLessonContent from '@/store/subject-lesson-content'
import totalLectureHoursPerCourse from '@/store/report/total-lecture-hours-per-course'

import graduationProjectRegisterRound from '@/store/graduation-project-register-round'
import graduationConditionScoreClass from '@/store/graduation-condition-score-class'
import graduationProjectScore from '@/store/graduation-project-score'
import practicalResearchCheckScore from '@/store/practical-research-check-score'
import qbQuestionType from '@/store/exam-question/qb-question-type'
import qbQuestionScale from '@/store/exam-question/qb-question-scale'
import qbQuestion from '@/store/exam-question/qb-question'
import qbAnswer from '@/store/exam-question/qb-answer'
import qbExamStructure from '@/store/exam-question/qb-exam-structure'
import qbExamStructureProperties from '@/store/exam-question/qb-exam-structure-properties'
import qbExams from '@/store/exam-question/qb-exam'
import researchInnovationReviewCouncil from '@/store/research/research-innovation-review-council'
import researchInnovationReviewer from '@/store/research/research-innovation-reviewer'
import researchReviewFormResult from '@/store/research-review-form-result'
import researchReviewPresent from '@/store/research-review-present'
import qbMultipleChoiceAnswer from '@/store/exam-question/qb-multiple-choice-answer'
import qbCriteriaMixExam from '@/store/exam-question/qb-criteria-mix-exam'
import teacherClass from '@/store/teacher-class'
import practicalResearchTopic from '@/store/practical-research-topic'
import courseScoreClass from '@/store/report/course-score-class'
import twTeacherHour from '@/store/teacherManagement/tw-teacher-hour'
import twTeacherQuota from '@/store/teacherManagement/tw-teacher-quota'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import practicalResearchClassGroupTeacher from "@/store/practical-research-class-group-teacher";
import ResearchInnovationReviewCouncil from "@/store/research/research-innovation-review-council";
import factTeacherType from "@/store/category/fact-teacher-type";
import twTimeFrameResearch from "@/store/tw-time-frame-research";
import twTimeQuotaResearch from "@/store/tw-time-quota-research";
import twTimeResearchConversionCriteriaType from "@/store/tw-time-research-conversion-criteria-type";
import twTimeResearchConversionCriteria from "@/store/tw-time-research-conversion-criteria";
import attendance from "@/router/routes/attendance";
import attendanceMachine from "@/store/attendance/attendance-machine";
import professionalQualification from "@/store/admission/category/professional-qualification";
import agencyLevel from "@/store/admission/category/agency-level";
import applicantType from "@/store/admission/category/applicant-type";
import generalEducationLevel from "@/store/admission/category/general-education-level";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    menu,
    category,
    building,
    classes,
    rooms,
    permission,
    award,
    allowance,
    discipline,
    policies,
    role,
    programme,
    semesterType,
    semester,
    trainingForm,
    trainingSystem,
    course,
    account,
    resource,
    studentAllowance,
    studentAward,
    studentCgpa,
    studentDiscipline,
    studentScholarship,
    programmeSubject,
    dropdown,
    creditClass,
    department,
    major,
    credentialType,
    credential,
    credentialImplement,
    certificateBlank,
    credentialSample,
    credentialCondition,
    certificateProcessType,
    credentialProcessType,
    credentialSampleConfig,
    credentialCreate,
    credentialStorage,
    credentialProcessRequest,
    certificateImplement,
    processRequest,
    subjectType,
    subject,
    examinationRoom,
    studentExaminationRoom,
    employee,
    teacherTeam,
    importStudent,
    creditClassTeacher,
    creditClassStudent,
    teacher,
    teacherSubject,
    schedule,
    day,
    turn,
    revenue,
    revenuePlan,
    exemption,
    revenueSchedule,
    receipt,
    courseSemester,
    studentClass,
    revenuetype,
    reportTeachingExamCondition,
    reportPointByCreditClass,
    student,
    creditClassRoom,
    avgStudentClass,
    conduct,
    studentPolicies,
    classReceiptsCourseSemester,
    reportPaymentStatusByCourseSemester,
    creditClassStudentPoint,
    graduationCondition,
    conductCriteria,
    graduationClass,
    programmeSubjectGroup,
    studentReserve,
    portalArticle,
    curriculumVitae,
    reportTeacherSpecializationAssignment,
    diploma,
    diplomaStudent,
    classLearningAlert,
    diplomaPlanStudentClass,
    parameter,
    examinationScheduler,
    examinationSchedulerSubject,
    calculateCgpa,
    creditClassBySubject,
    teacherDocument,
    creditClassMedia,
    accountFcmToken,
    notification,
    studentTransfer,
    retakeExamSecondTime,
    studentBeRepeated,
    examinationRoomTeacher,
    semesterExam,
    programmeReplaceThesisSubject,
    studentSuspend,
    documents,
    reportCertification,
    studentsRegisteredBySubject,
    logActivities,
    carousel,
    maintenance,
    maintenanceBackup,
    maintenanceRecovery,
    reviewBoard,
    factScientificTopics,
    factTopicLevels,
    factResearchRoles,
    factResearchFields,
    research,
    reviewer,
    member,
    approvalResearch,
    researchArticle,
    researchArticleRole,
    manageReport,
    scoreLockPassword,
    deductLevel,
    scoringFormula,
    generalScore,
    componentScore,
    invention,
    factResearchInventionType,
    inventionMember,
    techTransfer,
    techTransferMember,
    researchStudent,
    researchStudentMember,
    researchInstructor,
    factResearchArticleRole,
    researchArticleMember,
    factProvince,
    factNationality,
    factAward,
    factReligion,
    factStudentStatus,
    factStudentCreditClassStatus,
    factWard,
    factArticleCategory,
    factDiscipline,
    factInfringe,
    factTitle,
    factScholarship,
    factYear,
    factOrganization,
    systemParameter,
    factEthnic,
    chart,
    departmentType,
    qrEmployeeStudent,
    countStudentRegisterSubject,
    studentCancelSubject,
    factAcademicRank,
    factAcademicDegree,
    majorApproval,
    specialize,
    majorApprovalStudent,
    studentChoice,
    factAdmissionFormType,
    factAdmissionSubject,
    factAdmissionSubjectType,
    factAdmissionBlock,
    factAdmissionRole,
    admissionRound,
    admissionCommittee,
    admissionCommitteeEmployee,
    admissionForm,
    admissionThresholdScore,
    factAdmissionConduct,
    factAdmissionCriteriaType,
    factAdmissionStudentType,
    factAdmissionViolateType,
    factAdmissionDiscipline,
    factAdmissionCertificateType,
    factAdmissionConfirmationType,
    factAdmissionLanguageCertificateType,
    factPriorityZone,
    factHighSchool,
    factAcademicPerformance,
    factAdmissionScoreType,
    admissionMajor,
    admissionMajorForm,
    admissionPriorityScore,
    admissionRevenueType,
    admissionRevenue,
    criteriaSet,
    criteria,
    admissionRevenuePlan,
    admissionRevenueSchedule,
    admissionLanguageScore,
    admissionConfirmationCertificateAccept,
    admissionCarousel,
    admissionConfirmCertificate,
    formulaPriorityScore,
    formulaAdmissionScore,
    formulaAdmissionScoreCoefficient,
    applicantStudentPriorityOrder,
    admissionExaminationScheduler,
    admissionExaminationSchedulerSubject,
    admissionExaminationRoom,
    applicantStudentExaminationRoom,
    admissionExaminationRoomTeacher,
    admissionScore,
    aspirationInformation,
    admissionStandardScore,
    evaluateObjectGroup,
    evaluatedObjectGroup,
    setOfCriteria,
    setOfCriteriaObject,
    evaluateManagement,
    studentEvaluateCreditClass,
    setOfEvaluateLevel,
    setOfCriteriaType,
    employeeEvaluate,
    employeeSetOfCriteriaObject,
    studentEvaluate,
    configStudentCode,
    elearning,
    payment,
    backupSchedule,
    countStudentCancelSubject,
    factDisability,
    factGender,
    digitalLibrary,
    confirmAdmission,
    practicalResearchProgram,
    practicalResearchTopic,
    graduationDissertation,
    importAdmission,
    politicAdmissionConfirm,
    subjectLessonContent,
    practicalResearchClassGroup,
    practicalResearchClassStudent,
    graduationConditionScoreClass,
    totalLectureHoursPerCourse,
    graduationProjectRegisterRound,
    graduationProjectScore,
    practicalResearchCheckScore,
    researchInnovationReviewCouncil,
    researchInnovationReviewer,
    researchReviewFormResult,
    researchReviewPresent,
    qbQuestionType,
    qbQuestionScale,
    qbQuestion,
    qbAnswer,
    qbExamStructure,
    qbExamStructureProperties,
    qbExams,
    qbMultipleChoiceAnswer,
    qbCriteriaMixExam,
    teacherClass,
    courseScoreClass,
    twTeacherHour,
    twTeacherQuota,
    practicalResearchClassGroupTeacher,
    factTeacherType,
    twTimeFrameResearch,
    twTimeQuotaResearch,
    twTimeResearchConversionCriteriaType,
    twTimeResearchConversionCriteria,
    attendanceMachine,
    professionalQualification,
    agencyLevel,
    applicantType,
    generalEducationLevel,
  },
  strict: process.env.DEV,
})
